import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";

const InvWindow = ({items = [], resources = [], userInfo = {}, onClose, onSelectItem}) => {
    const {t} = useTranslation()
    const [filteredItems, setFilteredItems] = useState(items);
    const [itemTypes, setItemsTypes] = useState([]);

    const [nameFilter, setNameFilter] = useState("");
    const [typeFilter, setTypeFilter] = useState("");

    useEffect(() => {
        setItemsTypes([
            ...new Set(items.map(e => e.item.base_item.type))
        ]);
    }, [items]);

    useEffect(() => {
        const filtered = items.filter(item => {
            const matchesName = t(item.item.base_item.name).toLowerCase().includes(nameFilter.toLowerCase());
            const matchesType = typeFilter ? item.item.base_item.type === typeFilter : true;
            return matchesName && matchesType;
        });
        setFilteredItems(filtered);
    }, [nameFilter, typeFilter, items]);

    return (
        <div className={`w-100 m-0 d-flex flex-column ${onClose ? 'vh-100' : ''}`}>
            {onClose && (
                <div className="w-100">
                    <button className="btn-close btn-close-white" onClick={onClose}></button>
                </div>
                )
            }
            <div className="px-2">
                <div className="mb-3">
                    <label htmlFor="nameFilter" className="form-label">{t("Filter by name")}</label>
                    <input
                        type="text"
                        id="nameFilter"
                        className="form-control"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        placeholder={t("Search")}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="typeFilter" className="form-label">{t("Filter by type")}</label>
                    <select
                        id="typeFilter"
                        className="form-select"
                        value={typeFilter}
                        onChange={(e) => setTypeFilter(e.target.value)}
                    >
                        <option value="">{t("All")}</option>
                        {itemTypes.map((type, index) => (
                            <option key={index} value={type}>
                                {t(type)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="d-inline-flex overflow-x-auto overflow-hidden my-1" style={{ maxWidth: '100%', whiteSpace: 'nowrap' }}>
                {resources.map((value, index) => (
                    <div key={index} className="bg-secondary mx-1 p-1" style={{ display: 'inline-block' }}>
                        <small>{t(value.resource.name)}: {value.quantity}</small>
                    </div>
                ))}
            </div>
            <div className="px-2 flex-grow-1 overflow-auto">
                {filteredItems.map((value, index) => (
                    <div key={index} className="bg-secondary p-2 my-1 rounded m-0 align-items-center pointer-event" onClick={() =>onSelectItem(value)}>
                        {value.item.trade_ban_until !== null && new Date(value.item.trade_ban_until) > new Date() && (
                            <div className="w-100 bg-warning p-1 text-dark rounded small">
                                {t("Trade ban until")}: {new Date(value.item.trade_ban_until).toLocaleString()}
                            </div>
                        )}
                        <div className="row m-0">
                            <div className="col-8">
                                <div><small className="hint-text">{t(value.item.base_item.type)}</small></div>
                                <div className="my-1">{t(value.item.base_item.name)}</div>
                            </div>
                            <div className="col-4">
                                <small>
                                    {t("Active")}:
                                    {value.is_active ?
                                        <span className="text-success"> {t("Yes")}</span>
                                        :
                                        <span className="text-warning"> {t("No")}</span>
                                    }
                                </small>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InvWindow;
