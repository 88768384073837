import React, {useEffect, useRef, useState} from 'react';
import { Wheel } from '@boriska420/react-custom-roulette';
import {toast} from "react-toastify";
import axiosInstance from "../../axios";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const customOrder = {
    'coins': 1,
    'item': 2,
};



const generateColorShades = (firstColor, secondColor, count) => {
    const colors = [];
    const startColor = hexToRgb(firstColor);
    const endColor = hexToRgb(secondColor);

    for (let i = 0; i < count; i++) {
        const r = Math.round(startColor.r + (endColor.r - startColor.r) * (i / (count - 1)));
        const g = Math.round(startColor.g + (endColor.g - startColor.g) * (i / (count - 1)));
        const b = Math.round(startColor.b + (endColor.b - startColor.b) * (i / (count - 1)));
        colors.push(rgbToHex(r, g, b));
    }

    return colors;
};


const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255
    };
};


const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};


const OpenCase = () => {
    const { t } = useTranslation();
    const {caseName} = useParams()

    const [segments,setSegments] = useState([
        { option: '', style: { backgroundColor: 'orange', textColor: 'white' } },]);

    const [spinItems, setSpinItems] = useState([]);
    const [cases, setCases] = useState([]);

    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);


    const [mappedPrizes, setMappedPrizes] = useState([])



    useEffect(() => {
        const loadSpinPrizes = async () => {
            console.log("loadSpinPrizes")
            try {
                const response = await axiosInstance.get(`/uncategory/casePrizes/${caseName}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null){
                    setSpinItems(response.data.case_prizes.sort((a, b) => b.chance - a.chance ));
                }

            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch items."));
            }
        };

        const loadSpinCases = async () => {
            console.log("loadSpinCases")
            try {
                const response = await axiosInstance.get(`/user/inv/${caseName}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null){
                    setCases(response.data);
                }

            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch cases."));
            }
        };

        if(caseName !== null){
            loadSpinPrizes();
            loadSpinCases();
        }
    }, []);


    useEffect(() => {
        const loadItemsData = async () => {
            console.log("loadItemsData")
            try {
                const response = await axiosInstance.post(`/uncategory/items`,
                    {
                        "base_items_id": spinItems
                            .filter(e => e.prize_type === "item")
                            .map(e => Number(e.value))
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                if (response.data !== null) {
                    const mappedData = spinItems.map(e => {
                        e.itemData = response.data.find(i => i.id === Number(e.value));
                        return e;  // Повертаємо об'єкт з доданим полем itemData
                    });

                    const newMappedPrizes = generateColorShades("#f7a605", "#9605f7", spinItems.length).map((color, index) => {
                        mappedData[index].color = color;
                        return mappedData[index];
                    });
                    var reSortedMappedPrizes = []
                    for (let i = 0; i < newMappedPrizes.length; i++) {
                        if(i%2===0){
                            reSortedMappedPrizes.push(newMappedPrizes[i])
                        }else{
                            reSortedMappedPrizes = [newMappedPrizes[i], ...reSortedMappedPrizes]
                        }
                    }

                    setMappedPrizes(reSortedMappedPrizes)
                }
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch items data."));
            }
        };

        if (spinItems.length > 0) {
            loadItemsData();
        }
    }, [spinItems]);

    useEffect(() => {
        if (mappedPrizes && mappedPrizes.length > 0) {
            const newSegments = mappedPrizes.map(e => {
                return {
                    option: '',
                    optionSize: e.chance,
                    style: {
                        backgroundColor: e.color,
                        textColor: "white"
                    }
                };
            });
            setSegments(newSegments);
        }
    }, [mappedPrizes]);


    const spin = async () => {
        if(cases.length === 0){
            return
        }
        try {
            const response = await axiosInstance.post(`/user/inv/case/open`,
                {
                    "case_id": cases.pop()["item"]["id"]
                }, {headers: {'Content-Type': 'application/json'}
                });
            if (response.data !== null){
                const prizeIndex = mappedPrizes.findIndex(
                    (item) => item.prize_type === response.data['prize_type'] && item.value === response.data['value']
                );

                if (prizeIndex !== -1) {
                    setPrizeNumber(prizeIndex);
                    setMustSpin(true);
                } else {
                    toast.error(t("The prize was not found in the wheel elements."));
                }
            }

        } catch (error) {
            console.log(error);
            toast.error(t("Could not spin the wheel."));
        }
    };


    return (
        <div className="container mt-4">
            <div className="card bg-secondary">
                <div className="card-body">
                    <div className="d-flex justify-content-center align-items-center">
                        <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={prizeNumber}
                            data={segments}
                            backgroundColors={['#3e3e3e', '#df3428']}
                            textColors={['#ffffff']}
                            outerBorderWidth={0}
                            radiusLineWidth={0}
                            innerRadius={50}
                            spinDuration={Math.floor(Math.random() * 1) + 0.5}
                            onStopSpinning={() => {
                                setMustSpin(false);
                                const prize =  mappedPrizes[prizeNumber]
                                console.log(prize)
                                switch (prize.prize_type){
                                    case "item":
                                        toast.success(t("You win")+" " +t(prize.itemData.name));
                                        break;
                                    case "coins":
                                        toast.success(t("You win")+" " +prize.value + " " + t(prize.prize_type));
                                        break;
                                    default:
                                        toast.success(t("You win")+" something");
                                }
                            }}
                        />
                    </div>
                    <div className="text-center">{t("Attempts")}: {cases.length}</div>
                    <div className="text-center">
                        <button className={`btn btn-primary ${mustSpin || cases.length <= 0? "disabled" : ""}`}  onClick={spin}>{t("Open Case")}</button>
                    </div>

                </div>
            </div>
            <div>
                {mappedPrizes && [...mappedPrizes]
                    .sort((a, b) => {
                        const typeOrderDiff = (customOrder[a.prize_type] || Infinity) - (customOrder[b.prize_type] || Infinity);
                        return typeOrderDiff !== 0 ? typeOrderDiff : b.chance - a.chance;
                    })
                    .map(e => (
                        <div className="mt-2 bg-secondary p-1 d-flex align-items-center rounded-1" key={e.id}>
                            <div style={{ width: "1em", height: "1em", background: e.color }} className="m-1 rounded-1"></div>
                            {(() => {
                                switch (e.prize_type) {
                                    case 'coins':
                                        return <span>{e.value} {t(e.prize_type)}</span>;
                                    case 'item':
                                        return <span>{t(e.itemData.name)}</span>;
                                    default:
                                        return <span>{t(e.prize_type)}</span>;
                                }
                            })()}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default OpenCase;
