import React, { useEffect, useState } from 'react';
import axiosInstance from "../../axios";
import {toast} from "react-toastify";
import TradeCard from "./TradeCard";
import TradeInventory from "./TradeInventory";
import TradeCoinCard from "./TradeCoinCard";
import {useTranslation} from "react-i18next";

function Trade() {
    const { t } = useTranslation();
    const tg = window.Telegram.WebApp;
    const [tradeId, setTradeId] = useState(null);
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true)

    const [userInfo, setUserInfo] = useState({})
    const [counterpartyInfo, setCounterpartyInfo] = useState({})
    const [userInvItems, setUserInvItems] = useState([])
    const [userInvResources, setUserInvResources] = useState([])
    const [userWallet, setUserWallet] = useState({})
    const [userTradeItems, setUserTradeItems] = useState([])
    const [userTradePremiumCoins, setUserTradePremiumCoins] = useState(0)
    const [userApprove, setUserApprove] = useState(false)
    const [counterpartyTradeItems, setCounterpartyTradeItems] = useState([])
    const [counterpartyTradePremiumCoins, setCounterpartyTradePremiumCoins] = useState(0)
    const [counterpartyApprove, setCounterpartyApprove] = useState(false)
    const [tradeStatus, setTradeStatus] = useState("Unknown")
    const [canTrade, setCanTrade] = useState(false)


    const [addItemFormActive, setAddItemFormActive] = useState(false)
    const [addCoinsFormActive, setAddCoinsFormActive] = useState(false)



    useEffect(() => {
        const startParam = tg.initDataUnsafe.start_param
        setTradeId(startParam);
    },[])

    useEffect(() => {
        if(tradeStatus === "completed" || tradeStatus === "cancelled"){
            setCanTrade(false)
        }else{
            setCanTrade(true)
        }
    }, [tradeStatus]);

    useEffect(() => {
        const getTradeInfo = async () => {
            try {
                const response = await axiosInstance.get(`/trade/${tradeId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data) {
                    const isCurrentUser = tg.initDataUnsafe.user.id === response.data.a_user_id;

                    setUserInfo({
                        user_id: isCurrentUser ? response.data.a_user_id : response.data.b_user_id,
                        first_name: isCurrentUser ? response.data.a_user_first_name : response.data.b_user_first_name,
                    });
                    setUserTradePremiumCoins(isCurrentUser ? response.data.a_user_coins.amount : response.data.b_user_coins.amount);
                    if (isCurrentUser ? response.data.a_user_item : response.data.b_user_item) {
                        setUserTradeItems(isCurrentUser ? response.data.a_user_item : response.data.b_user_item);
                    }
                    setUserApprove(isCurrentUser ? response.data.a_user_approve_trade : response.data.b_user_approve_trade);

                    setCounterpartyInfo({
                        user_id: isCurrentUser ? response.data.b_user_id : response.data.a_user_id,
                        first_name: isCurrentUser ? response.data.b_user_first_name : response.data.a_user_first_name,
                    });
                    setCounterpartyTradePremiumCoins(isCurrentUser ? response.data.b_user_coins.amount : response.data.a_user_coins.amount);
                    if (isCurrentUser ? response.data.b_user_item : response.data.a_user_item) {
                        setCounterpartyTradeItems(isCurrentUser ? response.data.b_user_item : response.data.a_user_item);
                    }
                    setCounterpartyApprove(isCurrentUser ? response.data.b_user_approve_trade : response.data.a_user_approve_trade);
                    setTradeStatus(response.data.status)
                    setLoading(false);
                }

            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch trade info."));
            }
        };

        const getInventory = async () => {
            try {
                const response = await axiosInstance.get(`/user/inv`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null){
                    if(response.data.items !== null){
                        setUserInvItems(response.data.items)
                    }
                    if(response.data.resources !== null){
                        setUserInvResources(response.data.resources)
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error(t("Unable to get information about inventory items."));
            }
        };
        const getWallet = async () => {
            try {
                const response = await axiosInstance.get(`/user/info`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null){
                    if(response.data.items !== null){
                        setUserWallet(response.data.wallet)
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch user info."));
            }
        };
        if(tradeId != null){
            getTradeInfo()
            getInventory()
            getWallet()
        }
    }, [tradeId]);

    useEffect(() => {
        if(tradeId == null){
            return
        }
        const ws = new WebSocket(`wss://${axiosInstance.defaults.wsDomain}/trade/${tradeId}`);
        setSocket(ws);

        return () => {
            ws.close();
        };
    }, [loading]);

    useEffect(() => {
        if (!socket) return;

        const interval = setInterval(() => {
            socket.send(JSON.stringify({ action: 'ping' }));
        }, 30000); // send a ping every 30 seconds

        return () => clearInterval(interval);
    }, [socket]);

    useEffect(() => {
        if (!socket || !tradeId) return;

        socket.onopen = () => {
            console.log('WebSocket connection opened');
            socket.send(JSON.stringify({ action: 'auth', data: window.Telegram.WebApp.initData }));
        };

        socket.onmessage = (event) => {
            if(event.data !== null){
                const data = JSON.parse(event.data);
                switch (data.action){
                    case "TRADE_ITEM":
                        if (userInfo.user_id === data.data.user_id) {
                            setUserTradeItems(prevItems => {
                                return data.data.added
                                    ? [...prevItems, data.data.item]
                                    : prevItems.filter(item => item.item.id !== data.data.item.item.id);
                            });
                        } else {
                            setCounterpartyTradeItems(prevItems => {
                                return data.data.added
                                    ? [...prevItems, data.data.item]
                                    : prevItems.filter(item => item.item.id !== data.data.item.item.id);
                            });
                        }
                        break
                    case "TRADE_PREMIUMCOIN":
                        if (userInfo.user_id === data.data.user_id) {
                            setUserTradePremiumCoins(data.data.amount)
                        } else {
                            setCounterpartyTradePremiumCoins(data.data.amount)
                        }
                        break
                    case "TRADE_CONFIRMATION":
                        if (userInfo.user_id === data.data.user_id) {
                            setUserApprove(data.data.confirm)
                        } else {
                            setCounterpartyApprove(data.data.confirm)
                        }
                        break
                    case "TRADE_STATUS":
                        setTradeStatus(data.data.status)
                        break

                }
            }

        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };

        return () => {
            socket.close();
        };
    }, [socket]);

    const openSelectItemWindow = () =>{
        setAddItemFormActive(true)
    }

    const closeSelectItemWindow =() =>{
        setAddItemFormActive(false)
    }

    const onAddItemToTrade = async (item) => {
        if (item.item.trade_ban_until !== null && new Date(item.item.trade_ban_until) > new Date()) return
        try {
            const response = await axiosInstance.post(`/trade/${tradeId}/item`, {
                trade_id: tradeId,
                user_id: userInfo.user_id,
                item: item,
                added: true
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data !== null){
                setAddItemFormActive(false)
            }

        } catch (error) {
            console.log(error);
            toast.error(t("The item could not be added."));
        }
    }

    const onRemoveItemFromTrade = async (item) => {
        try {
            const response = await axiosInstance.post(`/trade/${tradeId}/item`, {
                trade_id: tradeId,
                user_id: userInfo.user_id,
                item: item,
                added: false
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data !== null){
                setAddItemFormActive(false)
            }

        } catch (error) {
            console.log(error);
            toast.error(t("The item could not be deleted."));
        }
    }

    const onConfirm = async () => {
        try {
            const response = await axiosInstance.post(`/trade/${tradeId}/confirm`, {
                trade_id: tradeId,
                user_id: userInfo.user_id,
                confirm: !userApprove
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data !== null){
                setAddItemFormActive(false)
            }

        } catch (error) {
            console.log(error);
            toast.error(t("The transaction confirmation could not be updated."));
        }
    }

    const onCancel = async () => {
        try {
            const response = await axiosInstance.post(`/trade/${tradeId}/cancel`, {
                trade_id: tradeId,
                user_id: userInfo.user_id,
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data !== null){
                setAddItemFormActive(false)
            }

        } catch (error) {
            console.log(error);
            toast.error(t("The transaction confirmation could not be updated."));
        }
    }

    const onAddPremiumCoinToTrade = async (amount) => {
        try {
            const response = await axiosInstance.post(`/trade/${tradeId}/premiumCoin`, {
                trade_id: tradeId,
                user_id: userInfo.user_id,
                amount: Number(amount)
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data !== null){
                setAddItemFormActive(false)
                toast.success(t("You have successfully added coins to the Trade."));
            }

        } catch (error) {
            console.log(error);
            toast.error(t("Failed to update the number of coins in trade."));
        }
    }

    const openSelectCoinWindow = () =>{
        setAddCoinsFormActive(true)
    }
    const closeSelectCoinWindow = () =>{
        setAddCoinsFormActive(false)
    }

    return (
        <div className="container mt-4">
            <div className="">
                {loading ? <></> :
                    <div>
                        <div>
                            <span>{t("Trade Status")}: {t(tradeStatus)}</span>
                        </div>
                        <div className="mt-3">
                            <TradeCard user_info={counterpartyInfo}
                                       user_items={counterpartyTradeItems}
                                       user_coins={counterpartyTradePremiumCoins}
                                       user_approve={counterpartyApprove}
                                       can_edit={false}
                            />
                            <div className="text-center">
                                <svg viewBox="-1 0 18 18" className="hint-fill" fill="none" xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" style={{transform: "rotate(90deg)"}}>
                                    <g><path  d="M5.62132 6.5L6.0607 6.93934C6.6464 7.5251 6.6464 8.4749 6.0607 9.0607C5.47487 9.6464 4.52513 9.6464 3.93934 9.0607L0.93934 6.06066C0.35355 5.47487 0.35355 4.52513 0.93934 3.93934L3.93934 0.93934C4.52513 0.35355 5.47487 0.35355 6.0607 0.93934C6.6464 1.52513 6.6464 2.47487 6.0607 3.06066L5.62132 3.5H8C8.8284 3.5 9.5 4.17157 9.5 5C9.5 5.82843 8.8284 6.5 8 6.5H5.62132zM10.3787 14.5H8C7.1716 14.5 6.5 13.8284 6.5 13C6.5 12.1716 7.1716 11.5 8 11.5H10.3787L9.9393 11.0607C9.3536 10.4749 9.3536 9.5251 9.9393 8.9393C10.5251 8.3536 11.4749 8.3536 12.0607 8.9393L15.0607 11.9393C15.6464 12.5251 15.6464 13.4749 15.0607 14.0607L12.0607 17.0607C11.4749 17.6464 10.5251 17.6464 9.9393 17.0607C9.3536 16.4749 9.3536 15.5251 9.9393 14.9393L10.3787 14.5z"></path></g>
                                </svg>
                            </div>
                            <TradeCard user_info={userInfo}
                                       user_items={userTradeItems}
                                       user_coins={userTradePremiumCoins}
                                       user_approve={userApprove}
                                       can_edit={canTrade}
                                       onAddItem={openSelectItemWindow}
                                       onRemoveItemFromTrade={onRemoveItemFromTrade}
                                       onOpenCoinForm={openSelectCoinWindow}
                            />
                        </div>
                        {
                            canTrade ?
                            <div className="d-flex m-3">
                                <button className="btn btn-danger w-100 m-1" onClick={onCancel}>{t("Cancel")}</button>
                                {userApprove ?
                                    <button className="btn btn-warning w-100 m-1" onClick={onConfirm}>{t("Undo")}</button>
                                    :
                                    <button className="btn btn-primary w-100 m-1" onClick={onConfirm}>{t("Confirm")}</button>
                                }

                            </div> : <></>
                        }

                    </div>
                }
            </div>
            <TradeInventory
                user_inv_items={userInvItems}
                user_inv_resources={userInvResources}
                user_trade_items={userTradeItems}
                active={addItemFormActive}
                onClose={closeSelectItemWindow}
                onSelectItem={onAddItemToTrade}
            />
            <TradeCoinCard
                userWallet={userWallet}
                visible={addCoinsFormActive}
                onAddCoins={onAddPremiumCoinToTrade}
                onClose={closeSelectCoinWindow}
            />
        </div>
    );
}

export default Trade;
