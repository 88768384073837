import React, { useEffect, useState } from 'react';
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";

function Shop() {
    const { t } = useTranslation();
    const [shopItems, setShopItems] = useState([]);

    const [resources, setResources] = useState([]);

    const [userHasResources, setUserHasResources] = useState([]);
    const [userWallet, setUserWallet] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getShopItems = async () => {
            try {
                const response = await axiosInstance.get(`/shop/items/`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null){
                    setShopItems(response.data);
                }

            } catch (error) {
                console.log(error);
                toast.error("Failed to fetch shop items.");
            }
        };

        const getUserWallet = async () => {
            try {
                const response = await axiosInstance.get(`/user/info/`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null){
                    setUserWallet(response.data.wallet);
                }

            } catch (error) {
                console.log(error);
                toast.error("Failed to fetch user info.");
            }
        };


        const getUserResources = async () => {
            try {
                const response = await axiosInstance.get(`/user/resources/`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data === null) {
                    return
                }
                const uniqueUserResources = response.data.reduce((acc, resource) => {
                    if (!acc.find(r => r.resource.id === resource.resource.id)) {
                        acc.push(resource);
                    }
                    return acc;
                }, []);

                setUserHasResources(uniqueUserResources);
            } catch (error) {
                console.log(error);
                toast.error("Failed to fetch user resources.");
            }
        };



        getShopItems();
        getUserResources();
        getUserWallet()
    }, []);

    useEffect(() => {
        const getResources = async () => {
            try {
                console.log(shopItems)
                const resourceIds = shopItems
                    .filter((e) => e.price_data.payment_type === "resource")
                    .map((e) => e.price_data.id);
                console.log(resourceIds)
                const uniqueResourceIds = [...new Set(resourceIds)];
                console.log(uniqueResourceIds)
                const response = await axiosInstance.post(`/uncategory/resources/`,
                    { "resources": uniqueResourceIds },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                if (response.data === null) {
                    return
                }
                const uniqueResources = response.data.reduce((acc, resource) => {
                    if (!acc.find(r => r.id === resource.id)) {
                        acc.push(resource);
                    }
                    return acc;
                }, []);

                setResources(uniqueResources);
            } catch (error) {
                console.log(error);
                toast.error("Failed to fetch resources.");
            }
        }
        getResources()
    }, [shopItems]);

    useEffect(() => {
        if (userWallet !== null && shopItems.length > 0){
            setLoading(false)
        }

    }, [userWallet, shopItems]);



    const handleBuyClick = async (e) => {
        try {
            const response = await axiosInstance.post(`/shop/buyItem/`,
                e,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response.status === 200) {
                toast.success("Purchase successful!");
            } else {
                toast.error("Purchase failed.");
            }
        } catch (error) {
            console.log(error);
            toast.error("An error occurred while processing your purchase.");
        }
    };

    return !loading &&(
        <div className="container mt-4">
            <h1 className="mb-4">{t("Shop")}</h1>
            <div>
                {shopItems.map((element) => (
                    <div key={element.id} className="bg-secondary p-1 my-2 rounded-2">
                        <div className="row m-0 align-items-center">
                            <div className="col-8">
                                <div className="">
                                    {t(element.base_item.name)}
                                </div>
                                <div className="small d-flex">
                                    <div>
                                        {element.price_data.payment_type === 'resource' && t(resources.find((el) => el.id === element.price_data.id)?.name)}
                                        {element.price_data.payment_type === 'premium_coins' && t("premium_coins")}
                                        :
                                    </div>
                                    <div>
                                        {element.price_data.payment_type === 'resource' && (userHasResources.find((el) => el.resource.id === element.price_data.id)?.quantity || 0)}
                                        {element.price_data.payment_type === 'premium_coins' && userWallet.premium_coins}
                                        <span> / {element.price_data.amount}</span>
                                    </div>
                                </div>
                                <div className="hint-text small">{t("Remain")}: {element.limit_amount}</div>
                            </div>
                            <div className="col-4">
                                <button className="btn btn-primary w-100" onClick={() => handleBuyClick(element)}>{t("Buy")}</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Shop;
