import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function Top() {
    const { chatId } = useParams();
    const [responseData, setResponseData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const getTopSettings = async () => {
            try {
                const response = await axiosInstance.get(`/chatSettings/tops/${chatId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setResponseData(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch TOPs settings.")+ JSON.stringify(error.response.data));
            }
        };

        getTopSettings();
    }, [chatId, t]);

    const handleInputChange = (e, field) => {
        setResponseData({
            ...responseData,
            [field]: e.target.checked,
        });
    };

    const handleUpdate = async () => {
        try {
            const response = await axiosInstance.post(`/chatSettings/tops/`, {...responseData}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                toast.success(t("TOP settings updated successfully."));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            toast.error(t("Failed to update TOP settings.")+ JSON.stringify(error.response.data));
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("TOPs Settings")}</h1>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="greetingsToggle"
                            checked={responseData.include_in_rating || false}
                            onChange={(e) => handleInputChange(e, 'include_in_rating')}
                        />
                        <label className="form-check-label" htmlFor="greetingsToggle">
                            {t("Include chat to TOPs")}
                        </label>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary w-100" onClick={() => handleUpdate()}>
                {t("Update")}
            </button>
        </div>
    );
}

export default Top;
